import PropTypes from 'prop-types';
import React from 'react';

export const Event = ({createdAtMilli, appVersion, eventName, publisherParameters, adid, isNew}) => {
    const dateTime = new Date(parseInt(createdAtMilli, 10));

    return <tr className={isNew ? 'table-info' : ''}>
        <td>{dateTime.toLocaleString()}</td>
        <td>{appVersion}</td>
        <td>{eventName}</td>
        <td>{publisherParameters}</td>
        <td>{adid}</td>
    </tr>;
};

Event.propTypes = {
    createdAtMilli: PropTypes.string.isRequired,
    appVersion: PropTypes.string.isRequired,
    eventId: PropTypes.string,
    eventName: PropTypes.string,
    publisherParameters: PropTypes.string,
    isNew: PropTypes.bool
};

export default Event;
