import {backendUrl} from "./url";
import {authorizationHeader} from "./auth";

function checkBodyError(body) {
    if (body.error) {
        const message = body.error.message || 'Неизвестная ошибка!';
        throw new Error(message);
    }
}

const getEvents = async function(page, filter) {
    const appName = (filter && filter.appsName) || '';
    const appVersion = (filter && filter.appVersion && filter.appVersion.trim()) || '';
    const adid = (filter && filter.adid && filter.adid.trim()) || '';
    const header = await authorizationHeader();
    const response = await fetch(
        backendUrl(`/events?page=${page}&appsName=${appName}&appVersion=${appVersion}&adid=${adid}`),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...header,
            }
        }
    );
    if (response.status !== 200) {
        throw new Error('Unexpected backend response status ' + response.status);
    }
    const body = await response.json();
    checkBodyError(body);
    return body;
}

const getAppsFromAdjust = async function() {
    const header = await authorizationHeader();
    const response = await fetch(
        backendUrl(`/apps`),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...header,
            }
        }

    );
    if (response.status !== 200) {
        throw new Error('Unexpected backend response status ' + response.status);
    }
    const body = await response.json();
    checkBodyError(body);
    return body;
}

export {
  getEvents,
  getAppsFromAdjust,
};
