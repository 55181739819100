import {backendUrl} from "./url";
import {authorizationHeader} from "./auth";

function checkBodyError(body) {
    if (body.error) {
        const message = body.error.message || 'Неизвестная ошибка!';
        throw new Error(message);
    }
}

const createApp = async function(name, app_id) {
    const header = await authorizationHeader();
    const response = await fetch(
        backendUrl(`/addApp`),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                  ...header,
            },
            body: JSON.stringify({
               name,
               app_id,
               is_enabled:true //temporary
             })
        }

    );
    if (response.status !== 200) {
        throw new Error('Unexpected backend response status ' + response.status);
    }
    const res = await response.json();
    checkBodyError(res);
    return res;
}

const deleteApp = async function(id) {
   const header = await authorizationHeader();
   const response = await fetch(
       backendUrl(`/app/${id}`),
       {
           method: 'DELETE',
           headers: {
               'Content-Type': 'application/json',
               ...header,
           }
       }

   );
   if (response.status !== 200) {
       throw new Error('Unexpected backend response status ' + response.status);
   }
   const res = await response.json();
   checkBodyError(res);
   return res;
}

export {
  createApp,
  deleteApp,
};
