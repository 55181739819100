import { Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import AppForm from '../AppForm'
export const AddApp = ({setReloadIndex}) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <Button variant='primary' onClick={() => setShow(true)}>
                Add app
            </Button>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add app</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AppForm setReloadIndex={setReloadIndex} setShow={setShow} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddApp;
