import {Container} from "react-bootstrap";
import {EventsList} from "../../components/EventsList";
import React from "react";
import Header from "../../components/Header";

export const AdjustEvents = () => {
    return <>
        <Header />
        <main>
            <Container>
                <h1>Adjust Events Stream</h1>
                <EventsList/>
            </Container>
        </main>
    </>;
};

export default AdjustEvents;
