import {Pagination} from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";

export const EventsListPagination = ({page, pagesCount, onPageChange}) => {
    const pagesRange = 5;

    const active = page;
    const items = [];
    const min = Math.max(1, active - pagesRange);
    const max = Math.min(pagesCount, active + pagesRange);
    const handleOnChange = x => onPageChange && onPageChange(x);

    for (let number = min; number <= max; number++) {
        items.push(
            <Pagination.Item key={number} onClick={() => handleOnChange(number)} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    }

    return <Pagination size="lg">
        <Pagination.First onClick={() => handleOnChange(1)} />
        {items}
        <Pagination.Last onClick={() => handleOnChange(pagesCount)} />
    </Pagination>;
};

EventsListPagination.propTypes = {
    page: PropTypes.number,
    pagesCount: PropTypes.number,
    onPageChange: PropTypes.func
};

export default EventsListPagination;
