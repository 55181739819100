import {Event} from "../Event";
import {Table, Container, Spinner} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import EventsListPagination from "../EventsListPagination";
import EventsListFilter from "../EventsListFilter";
import AddApp from "../AddApp";
import {getEvents, getAppsFromAdjust} from "../../services/api";
import {ErrorContext} from "../../context/errorContext";
import {useHistory} from "react-router-dom";
import {frontendUrl} from "../../services/url";

export const EventsList = ({appVersion, aaid}) => {
    const {setError, error} = useContext(ErrorContext);
    const [data, setData] = useState({});
    const [apps, setApps] = useState(null);
    const [prevData, setPrevData] = useState({});
    const [page, setPage] = useState(1);
    const history = useHistory();
    const [filter, setFilter] = useState({
        appVersion: '',
        appsName: [],
        adid: '',
        refreshInterval: 15
    });
    const [reloadIndex, setReloadIndex] = useState(1);

    const fetchEvents = async () => {
        try {
            const newData = await getEvents(page, filter)
            if (prevData && prevData.items && newData && newData.items && prevData.page === newData.page) {
                newData.items.forEach(x => x.isNew = !prevData.items.some(y =>
                    y.createdAtMilli === x.createdAtMilli &&
                    y.event === x.event &&
                    y.adid === x.adid &&
                    y.appsName === x.appsName &&
                    y.appVersion === x.appVersion)
                );
            }
            setData(newData);
        } catch (err) {
            if (err.message.indexOf('401') >= 0) {
                history.push(frontendUrl('/login'));
                return;
            }
            setError((err && err.message) || 'Unknown error');
        }
    }

    const fetchApps = async () => {
        try {
            const data = await getAppsFromAdjust();
            data && setApps(data);
        } catch (err) {
            if (err.message.indexOf('401') >= 0) {
                history.push(frontendUrl('/login'));
                return;
            }
            setError((err && err.message) || 'Unknown error');
        }
    }

    const handleFilterChange = (newFilter) => {
        setPrevData(data);
        setData({});
        setFilter(newFilter);
    }

    const handlePageChange = (newPage) => {
        if (page === 1) {
            setPrevData(data);
        }
        setData({});
        setPage(newPage);
    }

    useEffect(() => {
        setPrevData(data);
        fetchEvents();
        fetchApps();
        // eslint-disable-next-line
    }, [reloadIndex, filter, page]);

    useEffect(() => {
        if (filter && filter.refreshInterval && filter.refreshInterval > 0) {
            const interval = setInterval(
                () => setReloadIndex(reloadIndex + 1),
                filter.refreshInterval * 1000);
            return () => clearInterval(interval);
        }
    }, [filter, reloadIndex]);

    return <Container>
        <AddApp setReloadIndex={setReloadIndex} />
        <br />
        <br />
        <EventsListFilter apps={apps} setReloadIndex={setReloadIndex} onFilterChange={x => handleFilterChange(x)}/>
        <br />
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Date Time</th>
                    <th>App Version</th>
                    <th>Event Name</th>
                    <th>Publisher Parameters</th>
                    <th>Adid</th>
                </tr>
            </thead>
            <tbody>
            {data && !data.items && !!error &&
                <tr className="table-danger">
                    <td colSpan={4}>
                        {error}
                    </td>
                </tr>
            }
            {data && !data.items && !error &&
                <tr>
                    <td colSpan={4}>
                        <div className="d-flex justify-content-center">
                            <Spinner animation="border" />
                        </div>
                    </td>
                </tr>
            }
            {data && data.items && data.items.length === 0 &&
                <tr className="table-warning">
                    <td colSpan={4}>No data found for provided filter</td>
                </tr>
            }
                {data && data.items && data.items.map((x, i) => <Event appVersion={x.appVersion}
                                                                                  key={i}
                                                                                  isNew={x.isNew}
                                                                                  eventId={x.event}
                                                                                  eventName={x.eventName}
                                                                                  adid={x.adid}
                                                                                  createdAtMilli={x.createdAtMilli}
                                                                                  publisherParameters={x.publisherParameters}/>)}
            </tbody>
        </Table>
        {data && data.pagesCount > 0 &&
        <EventsListPagination page={page}
                              onPageChange={x => handlePageChange(x)}
                              pagesCount={(data && data.pagesCount) || 1}/>}

        <br/>
        <br/>
        <Table striped bordered>
            <thead>
                <tr>
                    <th>Data table legend</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Normal data</td>
                </tr>
                <tr>
                    <td>Normal data</td>
                </tr>
                <tr className="table-warning">
                    <td>No data</td>
                </tr>
                <tr className="table-danger">
                    <td>Error</td>
                </tr>
                <tr className="table-info">
                    <td>New data (It compares the previous query and the current one, the page number should be 1)</td>
                </tr>
            </tbody>
        </Table>
    </Container>;
};

EventsList.propTypes = {
    appVersion: PropTypes.string,
    adid: PropTypes.string,
};
