import { Button, Form } from 'react-bootstrap';
import React, { useState } from 'react';
import { createApp } from '../../services/apps';

export const AppForm = ({ setShow, setReloadIndex }) => {
    const [appName, setAppName] = useState('');
    const [appId, setAppId] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (appName.length > 0 && appId.length > 0) {
            await createApp(appName, appId);
            setShow(false);
            setReloadIndex(15)
        }
        else{
            alert("name and app_id are required")
        }
    };

    return (
        <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group controlId='formBasicEmail'>
                <Form.Label>App name</Form.Label>
                <Form.Control
                    type='text'
                    value={appName}
                    onChange={(e) => setAppName(e.target.value)}
                    placeholder='etc. Easy Beat'
                />
            </Form.Group>

            <Form.Group controlId='formBasicPassword'>
                <Form.Label>App id</Form.Label>
                <Form.Control
                    type='text'
                    value={appId}
                    onChange={(e) => setAppId(e.target.value)}
                    placeholder='etc. com.uminate.easybeat'
                />
            </Form.Group>
            <Button variant='primary' type='submit'>
                Submit
            </Button>
        </Form>
    );
};

export default AppForm;
