import 'bootstrap/dist/css/bootstrap.css';
import React, {useState} from "react";
import './App.css';
import AdjustEvents from "./pages/AdjustEvents";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import {isAuthenticated} from './services/auth';
import ErrorContext from './context/errorContext';
import Login from "./pages/Login";
import OAuthCallback from "./pages/OAuthCallback";
import {frontendUrl} from "./services/url";

const PrivateRoute = ({ children, ...props}) => (
    <Route
        {...props}
    >
        {
            isAuthenticated()
                ? children
                : <Redirect to={frontendUrl('/login')} />
        }
    </Route>
);

function App() {
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    return (
        <ErrorContext.Provider value={{
            error,
            setError,
            success,
            setSuccess
        }}>
            <Router>
                <Switch>
                    <Route path={frontendUrl('/login')}>
                        <Login />
                    </Route>
                    <Route path={frontendUrl('/callback')}>
                        <OAuthCallback />
                    </Route>
                    <PrivateRoute path={frontendUrl('/home')}>
                        <AdjustEvents />
                    </PrivateRoute>
                    <PrivateRoute path={frontendUrl('/')}>
                        <AdjustEvents />
                    </PrivateRoute>
                </Switch>
            </Router>
        </ErrorContext.Provider>
    );
}

export default App;
