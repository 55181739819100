import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {issueToken} from '../../services/auth';
import {frontendUrl} from '../../services/url';

const OAuthCallback = () => {
  const history = useHistory();
  useEffect(
    () => {
      (async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code && await issueToken(code)) {
          history.push(frontendUrl('/'));
        } else {
          history.push(frontendUrl('/login'));
        }
      })();
    },
    [history],
  );
  return (
    <>
      Перенаправление
    </>
  );
}

export default OAuthCallback;
