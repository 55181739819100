import {Badge, Row, Button, Form, Col} from "react-bootstrap";
import {isEqual} from "lodash";
import React, {useEffect, useState, useRef} from "react";
import {deleteApp} from '../../services/apps'

const EMPTY_FILTER = {
    appsName: [],
    appVersion: '',
    adid: '',
    refreshInterval: 15
};

export const EventsListFilter = ({apps, onFilterChange, setReloadIndex}) => {
    const [appsName, setAppsName] = useState([]);
    const [appVersion, setAppVersion] = useState('');
    const [adid, setAdid] = useState('');
    const [refreshInterval, setRefreshInterval] = useState(15);
    const [filter, setFilter] = useState(EMPTY_FILTER);
    const [canApply, setCanApply] = useState(false);
    const [canReset, setCanReset] = useState(false);
    const formRef = useRef(null);
    const handleReset = () => {
        setAppsName([]);
        setAppVersion('');
        setAdid('');
        setRefreshInterval(15);
        formRef.current.reset();
    }

    const handleFilterChange = () => {
        setFilter({appVersion, adid, appsName, refreshInterval});
        onFilterChange({appVersion, adid, appsName, refreshInterval});
    }

    const handleCheckChange  = (appName) => {
        if(!appsName.includes(appName)){
            setAppsName([...appsName, appName])
        }
        else{
            setAppsName([...appsName.filter(item=>item !== appName)])
        }
    }
    const handleDelete = (appId) =>{
      deleteApp(appId)
      setReloadIndex(10)
    }

    useEffect(() => {
        setCanApply(!isEqual(filter, {appVersion, adid, appsName, refreshInterval}));
        setCanReset(!isEqual({appVersion, adid, appsName, refreshInterval}, EMPTY_FILTER))
    }, [appsName, appVersion, adid, refreshInterval, filter]);

    return <Form ref={formRef}>
        <Form.Group>
            {
                apps && apps.length > 0 ?
                apps.map(({ name, appId })=> <div key={appId} style={{ display: 'flex'}}>
                <Form.Check type="checkbox" value={appId} label={name} onChange={e => handleCheckChange(e.target.value)} />
                <Button style={{marginLeft:"10px"}} variant="outline-danger" size="sm" onClick={()=> handleDelete(appId)}>×</Button>
                      </div> )
                      : <div>
                          {
                              apps && apps.length !== 0 && apps!=null? "...loading" :"Нет приложений в базе данных"
                          }
                      </div>

            }
        </Form.Group>
        <Form.Group>
            <Form.Label>App Version</Form.Label>
            <Form.Control type="text" placeholder="ex.: release build (9156)" value={appVersion} onChange={e => setAppVersion(e.target.value)}/>
        </Form.Group>
        <Form.Group>
            <Form.Label>Adjust Device ID (adid)</Form.Label>
            <Form.Control type="text" placeholder="ex.: eabbe46108eea59c52cf517bcfcf41e9" value={adid} onChange={e => setAdid(e.target.value)}/>
        </Form.Group>
        <Form.Group>
            <Form.Label>Refresh data interval in seconds. Value: <strong>{refreshInterval}</strong>s. Refresh is {refreshInterval === 0 ?
                <Badge variant="warning">disabled</Badge> :
                <Badge variant="success">enabled</Badge>}</Form.Label>
            <Form.Control type="range"
                          min="0"
                          max="300"
                          value={refreshInterval} onChange={e => setRefreshInterval(parseInt(e.target.value, 10) || 0)}/>
        </Form.Group>
        <Row>
            {canReset ?
            <Col md={2}>
                 <Button variant="secondary" type="button" onClick={() => handleReset()}>Reset to defaults</Button>
            </Col>: ''}
            {canApply ?
            <Col md={2}>
                 <Button variant="primary" type="button" onClick={() => handleFilterChange()}>Apply settings</Button>
            </Col> : ''}
        </Row>
    </Form>;
};

export default EventsListFilter;
